import { Mui } from '@osu/react-ui';
import { Link, useHistory, useLocation } from 'react-router-dom';
import SearchInput from '../../Common/components/Search/search-input';
import useRoleAuthentication from '../../Common/hooks/useRoleAuthentication';
import { NAVBAR_WRAPPER_ID } from '../../util/constants';
import AuthButton from '../containers/AuthButton';

const useStyles = Mui.makeStyles((theme) => {
  return {
    appBar: {
      top: '0',
      zIndex: theme.zIndex.appBar,
      padding: `0 ${theme.spacing(4)}`,
    },
    root: {
      flexGrow: 1,
    },
    searchInput: {
      // Hide on smaller screens
      display: 'none',
      [theme.breakpoints.up('lg')]: { 
        display: 'block',
      },
    },
  };
});

export default function Navbar(props = {}) {
  const {
    searchValue,
    setSearchValue,
  } = props;
  let history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const authDetails = useRoleAuthentication();
  
  let navMenuOptions = [
    {
      to: '/',
      children: 'Application Review',
    },
  ];

  if (authDetails.ADMIN_ANYCOMMITTEE) {
    navMenuOptions.push(
      {
        to: '/manage-committees',
        children: 'Manage',
      },
      {
        to: '/reports/',
        children: 'Reports',
      },
      {
        to: '/exports/',
        children: 'Exports',
      }
    );
  }

  if (authDetails.ADMIN_ANYCOMMITTEE || authDetails.SLT_READ_ONLY) {
    navMenuOptions.push({
      to: '/search',
      children: 'Search',
    });
  }

  if (process.env.REACT_APP_ENABLE_MANAGE_READERS === 'false') {
    navMenuOptions = navMenuOptions.filter((obj) => {
      return !obj.to.includes('manage');
    });
  }

  if(authDetails.CYCLE_MANAGER) {
    navMenuOptions.push(
      {
        to: '/manage-cycles',
        children: 'Cycles',
      },
      
    );
  }

  const slashEnding = (path) =>
    typeof path === 'string' ? (path.endsWith('/') ? path : `${path}/`) : null;

  const navProps = (to, index) => {
    const lastItem = navMenuOptions.length - 1 === index;
    let properties = {
      className: lastItem ? `margin-right-auto` : `margin-right-2`,
      color: 'secondary',
    };
    const currentLocation = slashEnding(location?.pathname);
    const displayPath = slashEnding(to?.pathname || to);

    const rootPathParsed = currentLocation.split('/')[1];
    const displayPathParsed = displayPath.split('/')[1];

    const current = displayPathParsed && rootPathParsed === displayPathParsed;
    if (displayPath === '/' ? currentLocation === '/' : current) {
      properties['aria-current'] = 'page';
      properties.color = 'primary';
    }

    return properties;
  };

  const handleSearch = () => {
    const newUrl = `/search/?searchValue=${searchValue}`;
    history.push(newUrl);
    setSearchValue('')
  };

  return (
    <Mui.AppBar
      id={NAVBAR_WRAPPER_ID}
      color="inherit"
      className={classes.appBar}
      position="sticky"
    >
      <Mui.Toolbar>
        {navMenuOptions.map(({ to, children }, index) => (
          <Mui.Button
            key={index}
            {...navProps(to, index)}
            role={undefined}
            component={Link}
            to={to}
          >
            {children}
          </Mui.Button>
        ))}

        {authDetails.ADMIN_ANYCOMMITTEE &&
          <div className={classes.searchInput}>
            <SearchInput inputValue={searchValue} setInputValue={setSearchValue} search={handleSearch} width={'25rem'}/>
          </div>
        }
        <AuthButton />
      </Mui.Toolbar>
      {props.children && <Mui.Divider />}
      {props.children}
    </Mui.AppBar>
  );
}
